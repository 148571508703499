import React, {useEffect, useState } from 'react'
import Breadcumbs from '../../components/Breadcumbs'
import Footer from '../../components/Footer'
import ImageHeader from '../../components/ImageHeader'
import Navigationbar from '../../components/Navigationbar'
import { Table } from 'react-bootstrap'
import axios from 'axios'

const FeeStructure = () => {
    const items = [{item:'Home',url:'/'}]
    const itemActive = 'Admissions / Fee Structure'

	const [admissions, setAdmissions] = useState({adAppForm: null,adFormalitiesDate: null,adResultDate: null,adEligibleDate: null,adStatus: null,adTestDate: null,adTestFee: null,admissionFeeMerit: null,admissionFeeSelf: null,admissionFeeOverseas: null,createdAt: null,id: null,lastDateToApply: null,securityDeposit: null,updatedAt: null})
	const [degreePrograms, setDegreePrograms] = useState([{ id:'',degTitle: '',degImage: '',degProspectus: '',degFeePerSemesterMerit: '',degFeePerSemesterSelf: '',degFeePerSemesterOverseas: '',degLevel: '',createdAt: '',updatedAt: ''}])
	const [miscellaneousFee, setMiscellaneousFee] = useState([{id: '',feeTitle: '',feeAmount: '',feeNote: '',createdAt: '',updatedAt: ''}])
	
	useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL+'/admissions').then(response=>setAdmissions(response.data.data)).catch(error=>console.log(error))
        axios.get(process.env.REACT_APP_API_URL+'/degreeprograms').then(response=>setDegreePrograms(response.data.data)).catch(error=>console.log(error))
        axios.get(process.env.REACT_APP_API_URL+'/miscellaneousfee').then(response=>setMiscellaneousFee(response.data.data)).catch(error=>console.log(error))
    }, [])
	console.log(admissions)
	console.log(degreePrograms)
	console.log(miscellaneousFee)

    return (
        <div>
            <Navigationbar></Navigationbar>
            <ImageHeader image={"/images/banner_2.png"}></ImageHeader>
            <Breadcumbs items={items} actives={itemActive}></Breadcumbs>
            <br></br>
            <br></br>
            <br></br>
            <div className="content-block">
                <div className="section-area section-sp2">
                    <div className="container">
						<Table bordered hover responsive style={{"--bs-table-bg": "none"}}>
							<thead style={{background:'#14062b',textAlign:'center',fontSize:'18pt'}}>
								<tr>
									<th colSpan='9' style={{color:'whitesmoke'}}>FEE STRUCTURE</th>
								</tr>
							</thead>
							<tbody style={{fontSize:'14pt'}}>
								<tr style={{background:'darkslateblue'}}>
									<td colSpan='9' width='45%' style={{color:'whitesmoke'}}>MISCELLANEOUS FEES SCHEDULE FOR ALL (03) ADMISSIONS CATEGORIES (NON-REFUNDABLE)</td>
									{/* <td colSpan='4' width='55%' style={{color:'whitesmoke'}}>ADMISSION AND SEMESTER / TUITION FEES SCHEDULE FOR ALL DIFFERENT ADMISSIONS CATEGORIES (NON-REFUNDABLE)</td> */}
								</tr>
								<tr style={{background:'thistle'}}>
									<td colSpan='3'>MISCELLANEOUS FEES</td>
									<td colSpan='6'>MISCELLANEOUS FEES AMOUNT</td>
								</tr>
								{
									miscellaneousFee.map((fee,index)=>{
										return(
											<tr key={index} style={{background:"whitesmoke"}}>
												<td colSpan='3'>{fee.feeTitle}</td>
												<td colSpan='6'>{fee.feeAmount===0?'':'Rs'+fee.feeAmount+'/-'} &nbsp;<span style={{color:'#757575',fontSize:'12pt'}}>{fee.feeNote}</span></td>
											</tr>
										)
									}) 
								}
								{
									degreePrograms.map((degree,index)=>{
										return(
											<>
												<tr style={{background:'thistle'}}>
													<td colSpan='3'>{degree.degTitle}</td>
													<td colSpan='2'>Merit</td>
													<td colSpan='2'>Self-Finance</td>
													<td colSpan='2'>Overseas</td>
												</tr>
												<tr style={{background:"whitesmoke"}}>
													<td colSpan='3'>Admission Fees (Once)</td>
													<td colSpan='2'>Rs. {admissions.admissionFeeMerit}/-</td>
													<td colSpan='2'>Rs. {admissions.admissionFeeSelf}/-</td>
													<td colSpan='2'>Rs. {admissions.admissionFeeOverseas}/-</td>
												</tr>
												<tr style={{background:"whitesmoke"}}>
													<td colSpan='3'>Semester / Tuition Fees from 1st Semester to 10th Semester</td>
													<td colSpan='2'>Rs. {degree.degFeePerSemesterMerit}/- </td> {/* &nbsp;<span style={{color:'#757575',fontSize:'12pt'}}>+ 5% Govt withholding Tax</span> */}
													<td colSpan='2'>Rs. {degree.degFeePerSemesterSelf}/- </td>
													<td colSpan='2'>Rs. {degree.degFeePerSemesterOverseas}/- </td>
												</tr>
											</>
										)
									})
								}
								<tr style={{background:"lightgrey"}}>
									<td colSpan='3'>Security Deposit</td>
									<td colSpan='6'>Rs. {admissions.securityDeposit}/- will be charged from each selected student of 
										all three admission categories and this amount is refundable 
										after successfully completion of five (05) years degree 
										program.
									</td>
								</tr>
								{/* <tr>
									<td colSpan='9' style={{background:'lightgrey'}}>
										<h4 style={{textDecoration:'underline',color:'#14062b',textAlign:'center'}}>5% ADVANCE WITH HOLDING TAX</h4> 
										<p><span style={{fontSize: '14pt'}}>As per Circular No.6 of 2013 of Income Tax 
											Ordinance, 2001 under the section 236I of 
											Government of Pakistan, Revenue Division, 
											Federal Board of Revenue, every educational 
											institution is required to collect advance 
											Income Tax at the rate of 5% on the amount of 
											fee paid to an educational institution which 
											exceeds two hundred thousand rupees annually. 
											The term of fee includes tuition fee and all 
											charges received by the educational institution, 
											by whatever name called, excluding the amount which 
											is refundable.</span>
										</p>
										<p><span style={{fontSize: '14pt'}}>A student who pays the fees more than 
											two hundred thousand rupees (200, 000/-) 
											annually, he / she has to pay this 5% 
											Advance Withholding Tax payable by the 
											each student.</span>
										</p>
									</td>
								</tr> */}

							</tbody>
						</Table>	
					</div>
				</div>
			</div>
            <br/>
            <br/>
            <Footer></Footer>
        </div>
    )
}

export default FeeStructure
